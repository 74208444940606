import React, { useEffect, useRef, useState } from 'react';
import Draggabilly from 'draggabilly';
import { images as initialImages } from './images';
import './contactSheet.css';

const ContactSheet = () => {
  const gridRef = useRef(null);
  const draggiesRef = useRef([]);
  const [images, setImages] = useState(initialImages);
  const [itemOrder, setItemOrder] = useState([]);

  useEffect(() => {
    if (!gridRef.current) return;

    const gridSize = 210; // 200px + 10px gutter
    const container = gridRef.current.getBoundingClientRect();
    const containerWidth = container.width;
    const columnsCount = Math.floor(containerWidth / gridSize);

    const items = gridRef.current.querySelectorAll('.grid-item');
    
    // Initialize each item's position in a grid layout
    items.forEach((item, index) => {
      const row = Math.floor(index / columnsCount);
      const col = index % columnsCount;
      item.style.left = `${col * gridSize}px`;
      item.style.top = `${row * gridSize}px`;
    });

    // Set up Draggabilly for each item
    draggiesRef.current = Array.from(items).map((item, index) => {
      const draggie = new Draggabilly(item, {
        containment: gridRef.current,
        grid: [gridSize, gridSize]
      });

      draggie.on('dragEnd', function() {
        // Get all items positions after drag
        const positions = Array.from(items).map((item, i) => ({
          id: item.dataset.id,
          left: parseInt(item.style.left),
          top: parseInt(item.style.top),
          index: i
        }));

        // Sort by position (top then left)
        positions.sort((a, b) => {
          if (a.top === b.top) return a.left - b.left;
          return a.top - b.top;
        });

        // Update order
        setItemOrder(positions.map((pos, i) => ({
          id: pos.id,
          order: i + 1
        })));
      });

      return draggie;
    });

    // Initial order
    setItemOrder(Array.from(items).map((item, i) => ({
      id: item.dataset.id,
      order: i + 1
    })));

    return () => {
      draggiesRef.current.forEach(draggie => draggie.destroy());
    };
  }, [images]);

  return (
    <div className="contact-sheet">
      <div ref={gridRef} className="grid-container">
        {images.map((image) => (
          <div
            key={image.id}
            className="grid-item"
            data-id={image.id}
          >
            <img
              src={image.src}
              alt={`Item ${image.id}`}
              draggable={false}
            />
            <div className="number">
              {itemOrder.find(item => item.id === image.id.toString())?.order || ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactSheet;